<template>
    <div v-if="show">
        <div class="loading">
            <van-loading text-color="#4873C0" color="#4873C0" vertical
                >{{msg}}</van-loading
            >
        </div>
    </div>
</template>
<script>
// import { reactive, ref, onMounted} from "vue";
import { Loading } from "vant";
export default {
    components: {
        vanLoading: Loading
    },
    props: {
        msg: {
            type: String,
            default: "加载中…"
        },
        show: {
            type: Boolean,
            default: false
        },
    },
};
</script>
<style scoped>
.box {
    width: 100%;
    background-color: #ecf2ff;
    min-height: 0;
    position: relative;
    padding-bottom: 0;
}
.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
