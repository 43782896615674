<template>
    <div v-if="show">
        <div class="loading">
            <van-empty :description="msg" />
        </div>
    </div>
</template>
<script>
import { Empty } from "vant";
export default {
    components: {
        vanEmpty: Empty
    },
    props: {
        msg: {
            type: String,
            default: "加载中…"
        },
        show: {
            type: Boolean,
            default: false
        },
    },
};
</script>
<style scoped>
.box {
    width: 100%;
    background-color: #ecf2ff;
    min-height: 0;
    position: relative;
    padding-bottom: 0;
}
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
